import React from "react";

import LayoutPage from "../components/LayoutPage";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <LayoutPage>
      <SEO title="404: Not found" />
      <div>
        
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          404...
        </h2>
      </div>
    </LayoutPage>
  );
}

export default NotFoundPage;
